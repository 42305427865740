
.label {
    text-align: right;
    min-width: 100px;
    padding-right: 15px;
    text-transform: uppercase;
    font-weight: 600;
    vertical-align: top;
}

td{
    padding: 6px;
}
