body {
  margin: 0;
  overflow-y: scroll;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.popover-form{
  width: 250px;
}


.selected-section{
  background: white;
  margin-right: -1px;
  border: 1px solid #d9d9d9;
  border-right-style: none;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 2px;
  color: #1677ff !important;
}

.not-selected-section{
  background: #f5f5f5;
  margin-right: -1px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 2px;
}
.not-selected-section:hover{
  color: #1677ff !important;
}

.add-section{
  background: white;
  margin-right: -1px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 2px;
}
.add-section:hover{
  color: #1677ff !important;
}

.H_ib_body {
    width: 350px !important;
}


.H_ib_content {
    line-height: 1.4 !important;
}

